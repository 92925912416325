import AbstractCrudService from '@/services/AbstractCrudService';
import {ChartDto} from '@/model/ChartDto';
import {StatistikFilter} from '@/model/StatistikFilter';
import RequestUtil from '@/utils/RequestUtil';

class StatistikService extends AbstractCrudService {

    private readonly basePath: string = '/statistik';

    public getStatistikAuswertungen() {
        return this.getList(this.basePath + '/auswertungen');
    }

    public getStatistik(filter: StatistikFilter) {
        return new Promise((resolve, reject) => {
            this.sendGet(this.basePath + '/chart?' + RequestUtil.convertObjectToRequestParams(filter))
                .then((response) => {
                    const chartDto: ChartDto = response.data;
                    resolve(this.createChartData(chartDto));
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    public getStatistikAuslastungNaechsteAbholtage() {
        return new Promise((resolve, reject) => {
            this.sendGet(this.basePath + '/chart/auslastungabholtage')
                .then((response) => {
                    const chartDto: ChartDto = response.data;
                    resolve(this.createChartData(chartDto));
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    private createChartData(chart: ChartDto): any {
        return {
            type: chart.typ,
            options: {
                responsive: true,
                indexAxis: chart.horizontal ? 'y' : 'x',
                plugins: {
                    title: {
                        display: chart.titel,
                        text: chart.titel,
                        align: 'start',
                        font: {
                            size: 18
                        }
                    },
                    subtitle: {
                        display: chart.untertitel,
                        text: chart.untertitel,
                        align: 'start'
                    },
                    legend: {
                        display: chart.showLegend,
                        position: 'top',
                        align: 'end'
                    },
                    tooltip: {
                        mode: 'point',
                        intersect: false,
                        callbacks: {
                            label: function (context: any) {
                                let label = context.dataset.label || '';
                                label += ': ' + (chart.horizontal ? context.parsed.x : context.parsed.y) + ' ' + chart.werteEinheit;
                                return label;
                            }
                        }
                    }
                },
                scales: {
                    x: {
                        stacked: chart.stacked,
                        title: {
                            display: chart.horizontal ? chart.yAchseLabel : chart.xAchseLabel,
                            text: chart.horizontal ? chart.yAchseLabel : chart.xAchseLabel
                        }
                    },
                    y: {
                        stacked: chart.stacked,
                        title: {
                            display: chart.horizontal ? chart.xAchseLabel : chart.yAchseLabel,
                            text: chart.horizontal ? chart.xAchseLabel : chart.yAchseLabel
                        }
                    }
                }
            },
            data: {
                labels: chart.xAchseKategorien,
                datasets: chart.serien
            }
        };
    }
}

// Export a singleton instance in the global namespace
export const statistikService = new StatistikService();
