import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, unref as _unref, createBlock as _createBlock, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "dashboard md:h-full" }
const _hoisted_2 = {
  class: "text-900 font-bold text-6xl mb-2 text-center",
  "data-test": "page-title"
}
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "grid dashboard-grid" }
const _hoisted_5 = { class: "col-12 lg:col-4 lg:h-full" }
const _hoisted_6 = { class: "p-1 lg:h-full" }
const _hoisted_7 = { class: "surface-card p-3 shadow-2 border-round flex flex-column lg:h-full dashboard-card" }
const _hoisted_8 = { class: "col-12 lg:col-4 lg:h-full" }
const _hoisted_9 = { class: "p-1 lg:h-full" }
const _hoisted_10 = { class: "surface-card p-3 shadow-2 border-round flex flex-column lg:h-full dashboard-card" }
const _hoisted_11 = { class: "col-12 lg:col-4 lg:h-full" }
const _hoisted_12 = { class: "p-1 lg:h-full" }
const _hoisted_13 = { class: "surface-card p-3 shadow-2 border-round flex flex-column lg:h-full dashboard-card" }
const _hoisted_14 = { class: "align-right" }

import {onMounted, reactive, ref, Ref} from 'vue';
import {useStore} from '@/store';
import {useRouter} from 'vue-router';
import {UserStatus} from '@/model/UserStatus';
import {AuthenticatedUser} from '@/model/AuthenticatedUser';
import {Aufgabe} from '@/model/Aufgabe';
import {aufgabeService} from '@/services/AufgabeService';
import {useNotification} from '@/composables/Notification';
import PageHeader from '@/components/PageHeader.vue';
import {statistikService} from '@/services/StatistikService';


export default /*@__PURE__*/_defineComponent({
  __name: 'Home',
  setup(__props) {

const store = useStore();
const router = useRouter();
const user: Ref<AuthenticatedUser> = ref(store.state.user);
const userStatus: Ref<UserStatus> = ref(store.state.status);
const notification = reactive(useNotification());
const aufgaben: Ref<Aufgabe[]> = ref([]);
const chartOptions: Ref<any> = ref({});

onMounted(() => {
    loadChart();
    loadAufgaben();
});

function loadChart() {
    statistikService.getStatistikAuslastungNaechsteAbholtage()
        .then((response) => {
            chartOptions.value = response;
        })
        .catch((error: any) => {
            notification.handleApiError(error, 'Beim Lesen des Charts ist ein Fehler aufgetreten!');
        });
}

function loadAufgaben() {
    aufgabeService.getOffeneAufgaben()
        .then((response: any) => {
            const aufgabenList: Aufgabe[] = response.data.content;
            if (aufgabenList.length === 1 && !aufgabenList[0].id) {
                aufgaben.value = [];
                return;
            }

            aufgaben.value = aufgabenList;
        })
        .catch((error: any) => {
            notification.handleApiError(error, 'Beim Lesen der Aufgaben ist ein Fehler aufgetreten!');
        });
}

function closeAufgabe(aufgabe: Aufgabe) {
    notification.confirmDialog('Willst du die Aufgabe als erledigt markieren?', () => {
        aufgabe.erledigtAm = new Date();
        aufgabeService.saveAufgabe(aufgabe)
            .then((response: any) => {
                notification.toastInfo('Die Aufgabe ist als erledigt markiert.');
                loadAufgaben();
            })
            .catch((error: any) => {
                notification.handleApiError(error, 'Beim Speichern der Aufgabe ist ein Fehler aufgetreten!');
            })
    })
}

return (_ctx: any,_cache: any) => {
  const _component_Button = _resolveComponent("Button")!
  const _component_Chart = _resolveComponent("Chart")!
  const _component_Card = _resolveComponent("Card")!
  const _component_DataView = _resolveComponent("DataView")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(PageHeader, {
      "show-header": false,
      messages: notification.messages
    }, null, 8, ["messages"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _cache[6] || (_cache[6] = _createTextVNode("Hallo ")),
        (user.value)
          ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(user.value.displayname), 1))
          : _createCommentVNode("", true),
        _cache[7] || (_cache[7] = _createTextVNode("! "))
      ]),
      _cache[9] || (_cache[9] = _createElementVNode("div", { class: "text-700 text-xl mb-4 text-center line-height-3" }, "Willkommen zur IGS-Bestellverwaltung", -1)),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_Button, {
                label: "Bestellung anlegen",
                class: "p-button-primary p-button-lg w-full mt-1 mb-1 dashboard-shortcut",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(router).push('/bestellungen/neu'))),
                "data-test": "home-shortcut-bestellung-anlegen"
              }),
              _createVNode(_component_Button, {
                label: "Bestellungen",
                class: "p-button-primary p-button-lg w-full mt-1 mb-1 dashboard-shortcut",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_unref(router).push('/bestellungen'))),
                "data-test": "home-shortcut-bestellungen"
              }),
              (userStatus.value.isAdmin)
                ? (_openBlock(), _createBlock(_component_Button, {
                    key: 0,
                    label: "Sammelbestellungen",
                    class: "p-button-primary p-button-lg w-full mt-1 mb-1 dashboard-shortcut",
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_unref(router).push('/sammelbestellungen'))),
                    "data-test": "home-shortcut-sammelbestellungen"
                  }))
                : _createCommentVNode("", true),
              (userStatus.value.isKassierer)
                ? (_openBlock(), _createBlock(_component_Button, {
                    key: 1,
                    label: "Kunden",
                    class: "p-button-primary p-button-lg w-full mt-1 mb-1 dashboard-shortcut",
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_unref(router).push('/kunden'))),
                    "data-test": "home-shortcut-kunden"
                  }))
                : _createCommentVNode("", true),
              (userStatus.value.isAdmin)
                ? (_openBlock(), _createBlock(_component_Button, {
                    key: 2,
                    label: "Kalender",
                    class: "p-button-primary p-button-lg w-full mt-1 mb-1 dashboard-shortcut",
                    onClick: _cache[4] || (_cache[4] = ($event: any) => (_unref(router).push('/kalender'))),
                    "data-test": "home-shortcut-kalender"
                  }))
                : _createCommentVNode("", true),
              (userStatus.value.isKassierer)
                ? (_openBlock(), _createBlock(_component_Button, {
                    key: 3,
                    label: "Kasse",
                    class: "p-button-primary p-button-lg w-full mt-1 mb-1 dashboard-shortcut",
                    onClick: _cache[5] || (_cache[5] = ($event: any) => (_unref(router).push('/kasse'))),
                    "data-test": "home-shortcut-kasse"
                  }))
                : _createCommentVNode("", true)
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createVNode(_component_Chart, {
                type: chartOptions.value.type,
                data: chartOptions.value.data,
                options: chartOptions.value.options,
                height: 340
              }, null, 8, ["type", "data", "options"])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("div", _hoisted_13, [
              _createVNode(_component_DataView, {
                value: aufgaben.value,
                layout: "list"
              }, {
                empty: _withCtx(() => [
                  _createVNode(_component_Card, { class: "mt-1" }, {
                    content: _withCtx(() => _cache[8] || (_cache[8] = [
                      _createElementVNode("div", null, "Es gibt gerade keine aktuellen Aufgaben zu erledigen.", -1)
                    ])),
                    _: 1
                  })
                ]),
                list: _withCtx((slotProps) => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(slotProps.items, (item, index) => {
                    return (_openBlock(), _createBlock(_component_Card, {
                      key: index,
                      class: "mt-1 mb-2",
                      "data-test": "aufgabe-card"
                    }, {
                      title: _withCtx(() => [
                        _createElementVNode("div", null, _toDisplayString(item.titel), 1)
                      ]),
                      content: _withCtx(() => [
                        _createElementVNode("div", null, _toDisplayString(item.text), 1)
                      ]),
                      footer: _withCtx(() => [
                        _createElementVNode("div", _hoisted_14, [
                          (userStatus.value.isAdmin)
                            ? (_openBlock(), _createBlock(_component_Button, {
                                key: 0,
                                type: "button",
                                icon: "pi pi-check-square",
                                label: "Erledigt",
                                onClick: ($event: any) => (closeAufgabe(item)),
                                "data-test": "close-aufgabe-button"
                              }, null, 8, ["onClick"]))
                            : _createCommentVNode("", true)
                        ])
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                ]),
                _: 1
              }, 8, ["value"])
            ])
          ])
        ])
      ])
    ])
  ], 64))
}
}

})