import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "main-section",
  class: "pt-4 pl-4 pr-4"
}

import Navigation from '@/components/Navigation.vue';
export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(Navigation),
    _createElementVNode("section", _hoisted_1, [
      _createVNode(_component_router_view)
    ])
  ], 64))
}
}

})