export enum UserRoleEnum {
    ROLE_ADMIN = 'ROLE_ADMIN',
    ROLE_KASSIERER = 'ROLE_KASSIERER',
    ROLE_HELFER = 'ROLE_HELFER'
}

export class UserRole {
    public id: number = 0;
    public name: string = '';
}
