<template>
    <Menubar :model="mainMenuItems" :exact="false" data-test="navigation">
        <template #start>
            <router-link :to="{ path: '/'}" data-test="navigation-home">
                <img alt="logo" src="../assets/orange.gif" height="30" class="mr-2">
            </router-link>
        </template>
        <template #item="{ item, props, hasSubmenu }">
            <router-link v-if="item.route" v-slot="{ isActive, href, navigate }" :to="item.route" custom>
                <a :href="href" v-bind="props.action" @click="navigate" :class="isActive ? 'router-link-active' : ''">
                    <span v-if="item.icon" :class="'p-menuitem-icon ' + item.icon" />
                    <span class="p-menuitem-text">{{ item.label }}</span>
                </a>
            </router-link>
            <a v-else :href="item.url" :target="item.target" v-bind="props.action">
                <span v-if="item.icon" :class="'p-menuitem-icon ' + item.icon" />
                <span class="p-menuitem-text">{{ item.label }}</span>
                <span v-if="hasSubmenu" class="pi pi-fw pi-angle-down ml-2" />
            </a>
        </template>
        <template #end v-if="userStatus.isLoggedIn">
            <Button type="button" class="p-button-outlined p-button-plain" icon="pi pi-ellipsis-v"
                    @click="toggleHelpMenu" aria-haspopup="true" aria-controls="overlay_menu"
                    data-test="navigation-end"/>
            <Menu id="overlay_menu" ref="helpMenu" :model="helpMenuItems" :popup="true">
                <template #item="{ item, props }">
                    <router-link v-if="item.route" v-slot="{ isActive, href, navigate }" :to="item.route" custom>
                        <a :href="href" v-bind="props.action" @click="navigate" :class="isActive ? 'router-link-active' : ''">
                            <span v-if="item.icon" :class="'p-menuitem-icon ' + item.icon" />
                            <span class="p-menuitem-text">{{ item.label }}</span>
                        </a>
                    </router-link>
                    <a v-else :href="item.url" :target="item.target" v-bind="props.action">
                        <span v-if="item.icon" :class="'p-menuitem-icon ' + item.icon" />
                        <span class="p-menuitem-text">{{ item.label }}</span>
                    </a>
                </template>
            </Menu>
        </template>
    </Menubar>
</template>

<script setup lang="ts">
import {ref, Ref, watch} from 'vue';
import {useRouter} from 'vue-router';
import {useStore} from '@/store';
import {UserStatus} from '@/model/UserStatus';

const router = useRouter();
const store = useStore();
const userStatus: Ref<UserStatus> = ref(store.state.status);
const helpMenu = ref();

const mainMenuItems = ref([

    {
        label: 'Bestellungen',
        route: '/bestellungen',
        visible: () => userStatus.value.isLoggedIn,
    },
    {
        label: 'Sammelbestellungen',
        route: '/sammelbestellungen',
        visible: () => userStatus.value.isAdmin,
    },
    {
        label: 'Kasse',
        route: '/kasse',
        visible: () => userStatus.value.isKassierer,
    },
    {
        label: 'Zahlungen',
        route: '/zahlungen',
        visible: () => userStatus.value.isKassierer,
    },
    {
        label: 'Kunden',
        route: '/kunden',
        visible: () => userStatus.value.isKassierer,
    },
    {
        label: 'Bestellübersicht',
        route: '/bestelluebersicht',
        visible: () => userStatus.value.isAdmin,
    },
    {
        label: 'Kalender',
        route: '/kalender',
        visible: () => userStatus.value.isAdmin,
    },
    {
        label: 'Statistik',
        route: '/statistik',
        visible: () => userStatus.value.isAdmin,
    },
    {
        label: 'Newsletter',
        visible: () => userStatus.value.isAdmin,
        items: [
            {
                label: 'Empfänger',
                route: '/newsletter/empfaenger'
            },
            {
                label: 'Versand',
                route: '/newsletter/versand'
            }
        ]
    },
    {
        label: 'Administration',
        visible: () => userStatus.value.isAdmin,
        items: [
            {
                label: 'Produkte',
                route: '/administration/produkte'
            },
            {
                label: 'Produktkategorien',
                route: '/administration/produktkategorien'
            },
            {
                label: 'Lagerbestand',
                route: '/administration/lagerbestand'
            },
            {
                label: 'Freier Verkauf',
                route: '/administration/freierverkauf'
            },
            {
                label: 'Abholtage',
                route: '/administration/abholtage'
            },
            {
                label: 'Zahlungsarten',
                route: '/administration/zahlungsarten'
            },
            {
                label: 'Geräte',
                route: '/administration/devices'
            },
            {
                label: 'Benutzer',
                route: '/administration/user'
            },
            {
                label: 'Konfiguration',
                route: '/administration/konfiguration'
            }
        ]
    }
]);

const helpMenuItems = ref([
    {
        label: 'Hilfe',
        route: '/hilfe',
        icon: 'pi pi-question-circle',
        visible: () => userStatus.value.isLoggedIn
    },
    {
        label: 'Einstellungen',
        route: '/einstellungen',
        icon: 'pi pi-cog',
        visible: () => userStatus.value.isLoggedIn
    },
    {
        label: 'Logout',
        route: '/',
        icon: 'pi pi-power-off',
        visible: () => userStatus.value.isLoggedIn,
        command: () => logout()
    }
]);

watch(() => store.state.status, (newValue, oldValue) => {
    userStatus.value = newValue;
});

function toggleHelpMenu(event: any) {
    helpMenu.value.toggle(event);
}

function logout() {
    store.dispatch('signOut');
    router.push('/login');
}
</script>

<style>

.p-menubar {
    border-radius: 0 !important;
    padding: 0 1.5rem 0 1.3rem !important;
    min-height: 50px;
}

.p-menubar-root-list {
    gap: 0 !important;
}

.p-menubar-item-link {
    padding: 1rem 0.5rem !important;
}

.p-menubar-item-link.router-link-active {
    font-weight: bold;
    background-color: #e8e8e8;
}
</style>
