import {PosPrintData} from '@/model/PosPrintData';
import {PosCardReaderResult} from '@/model/PosCardReaderResult';

export class Warenkorb {
    public positionen: WarenkorbPosition[] = [];
    public kunde: string = '';
    public zahlung: WarenkorbZahlung = new WarenkorbZahlung();
    public printData?: PosPrintData;
}

export class WarenkorbPosition {
    public bestellungId: number = 0;
    public bestellnummer: string = '';
    public r2oId: number = 0;
    public igsId: number = 0;
    public artikelnummer: string = '';
    public igsBestellPositionId: number = 0;
    public bezeichnung: string = '';
    public einzelpreis: number = 0;
    public steuersatz: number = 0;
    public menge: number = 0;
    public kiloware: boolean = false;
    public rabatt?: Rabatt;
    public wirdDirektAbgeholt: boolean = true;
}

export class WarenkorbZahlung {
    public zahlungsmethode?: Zahlungsmethode;
    public rechnungstyp?: Rechnungstyp;
    public druckerId?: number;
    public userId?: number;
    public zahlungsdatum?: Date;
    public barBetrag?: number;
    public testmodus: boolean = false;
    public rabatt: Rabatt = new Rabatt();
    public cardReaderResult?: PosCardReaderResult;
}

export enum Zahlungsmethode {
    BARZAHLUNG = 492287,
    KARTENZAHLUNG = 492288,
    GUTSCHEIN = 492289
}

export enum Rechnungstyp {
    RECHNUNG = 1
}

export enum RabattTyp {
    GUTSCHEIN = 124144,
    RABATT = 121891
}

export enum RabattEinheit {
    EURO = 'currency',
    PROZENT = 'percent'
}

export class Rabatt {
    public typ?: RabattTyp;
    public einheit?: RabattEinheit;
    public betrag: number = 0;
}
