export default class JsonUtil {
    public static stringify(obj: any) {
        return JSON.stringify(obj, (key, value) => {
            if (value === null || value === undefined || value === '') {
                return undefined;
            }
            return value;
        });
    }
}
