import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, mergeProps as _mergeProps, createBlock as _createBlock, createSlots as _createSlots } from "vue"
import _imports_0 from '../assets/orange.gif'


const _hoisted_1 = ["href", "onClick"]
const _hoisted_2 = { class: "p-menuitem-text" }
const _hoisted_3 = ["href", "target"]
const _hoisted_4 = { class: "p-menuitem-text" }
const _hoisted_5 = {
  key: 1,
  class: "pi pi-fw pi-angle-down ml-2"
}
const _hoisted_6 = ["href", "onClick"]
const _hoisted_7 = { class: "p-menuitem-text" }
const _hoisted_8 = ["href", "target"]
const _hoisted_9 = { class: "p-menuitem-text" }

import {ref, Ref, watch} from 'vue';
import {useRouter} from 'vue-router';
import {useStore} from '@/store';
import {UserStatus} from '@/model/UserStatus';


export default /*@__PURE__*/_defineComponent({
  __name: 'Navigation',
  setup(__props) {

const router = useRouter();
const store = useStore();
const userStatus: Ref<UserStatus> = ref(store.state.status);
const helpMenu = ref();

const mainMenuItems = ref([

    {
        label: 'Bestellungen',
        route: '/bestellungen',
        visible: () => userStatus.value.isLoggedIn,
    },
    {
        label: 'Sammelbestellungen',
        route: '/sammelbestellungen',
        visible: () => userStatus.value.isAdmin,
    },
    {
        label: 'Kasse',
        route: '/kasse',
        visible: () => userStatus.value.isKassierer,
    },
    {
        label: 'Zahlungen',
        route: '/zahlungen',
        visible: () => userStatus.value.isKassierer,
    },
    {
        label: 'Kunden',
        route: '/kunden',
        visible: () => userStatus.value.isKassierer,
    },
    {
        label: 'Bestellübersicht',
        route: '/bestelluebersicht',
        visible: () => userStatus.value.isAdmin,
    },
    {
        label: 'Kalender',
        route: '/kalender',
        visible: () => userStatus.value.isAdmin,
    },
    {
        label: 'Statistik',
        route: '/statistik',
        visible: () => userStatus.value.isAdmin,
    },
    {
        label: 'Newsletter',
        visible: () => userStatus.value.isAdmin,
        items: [
            {
                label: 'Empfänger',
                route: '/newsletter/empfaenger'
            },
            {
                label: 'Versand',
                route: '/newsletter/versand'
            }
        ]
    },
    {
        label: 'Administration',
        visible: () => userStatus.value.isAdmin,
        items: [
            {
                label: 'Produkte',
                route: '/administration/produkte'
            },
            {
                label: 'Produktkategorien',
                route: '/administration/produktkategorien'
            },
            {
                label: 'Lagerbestand',
                route: '/administration/lagerbestand'
            },
            {
                label: 'Freier Verkauf',
                route: '/administration/freierverkauf'
            },
            {
                label: 'Abholtage',
                route: '/administration/abholtage'
            },
            {
                label: 'Zahlungsarten',
                route: '/administration/zahlungsarten'
            },
            {
                label: 'Geräte',
                route: '/administration/devices'
            },
            {
                label: 'Benutzer',
                route: '/administration/user'
            },
            {
                label: 'Konfiguration',
                route: '/administration/konfiguration'
            }
        ]
    }
]);

const helpMenuItems = ref([
    {
        label: 'Hilfe',
        route: '/hilfe',
        icon: 'pi pi-question-circle',
        visible: () => userStatus.value.isLoggedIn
    },
    {
        label: 'Einstellungen',
        route: '/einstellungen',
        icon: 'pi pi-cog',
        visible: () => userStatus.value.isLoggedIn
    },
    {
        label: 'Logout',
        route: '/',
        icon: 'pi pi-power-off',
        visible: () => userStatus.value.isLoggedIn,
        command: () => logout()
    }
]);

watch(() => store.state.status, (newValue, oldValue) => {
    userStatus.value = newValue;
});

function toggleHelpMenu(event: any) {
    helpMenu.value.toggle(event);
}

function logout() {
    store.dispatch('signOut');
    router.push('/login');
}

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Menu = _resolveComponent("Menu")!
  const _component_Menubar = _resolveComponent("Menubar")!

  return (_openBlock(), _createBlock(_component_Menubar, {
    model: mainMenuItems.value,
    exact: false,
    "data-test": "navigation"
  }, _createSlots({
    start: _withCtx(() => [
      _createVNode(_component_router_link, {
        to: { path: '/'},
        "data-test": "navigation-home"
      }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createElementVNode("img", {
            alt: "logo",
            src: _imports_0,
            height: "30",
            class: "mr-2"
          }, null, -1)
        ])),
        _: 1
      })
    ]),
    item: _withCtx(({ item, props, hasSubmenu }) => [
      (item.route)
        ? (_openBlock(), _createBlock(_component_router_link, {
            key: 0,
            to: item.route,
            custom: ""
          }, {
            default: _withCtx(({ isActive, href, navigate }) => [
              _createElementVNode("a", _mergeProps({ href: href }, props.action, {
                onClick: navigate,
                class: isActive ? 'router-link-active' : ''
              }), [
                (item.icon)
                  ? (_openBlock(), _createElementBlock("span", {
                      key: 0,
                      class: _normalizeClass('p-menuitem-icon ' + item.icon)
                    }, null, 2))
                  : _createCommentVNode("", true),
                _createElementVNode("span", _hoisted_2, _toDisplayString(item.label), 1)
              ], 16, _hoisted_1)
            ]),
            _: 2
          }, 1032, ["to"]))
        : (_openBlock(), _createElementBlock("a", _mergeProps({
            key: 1,
            href: item.url,
            target: item.target
          }, props.action), [
            (item.icon)
              ? (_openBlock(), _createElementBlock("span", {
                  key: 0,
                  class: _normalizeClass('p-menuitem-icon ' + item.icon)
                }, null, 2))
              : _createCommentVNode("", true),
            _createElementVNode("span", _hoisted_4, _toDisplayString(item.label), 1),
            hasSubmenu
              ? (_openBlock(), _createElementBlock("span", _hoisted_5))
              : _createCommentVNode("", true)
          ], 16, _hoisted_3))
    ]),
    _: 2
  }, [
    (userStatus.value.isLoggedIn)
      ? {
          name: "end",
          fn: _withCtx(() => [
            _createVNode(_component_Button, {
              type: "button",
              class: "p-button-outlined p-button-plain",
              icon: "pi pi-ellipsis-v",
              onClick: toggleHelpMenu,
              "aria-haspopup": "true",
              "aria-controls": "overlay_menu",
              "data-test": "navigation-end"
            }),
            _createVNode(_component_Menu, {
              id: "overlay_menu",
              ref_key: "helpMenu",
              ref: helpMenu,
              model: helpMenuItems.value,
              popup: true
            }, {
              item: _withCtx(({ item, props }) => [
                (item.route)
                  ? (_openBlock(), _createBlock(_component_router_link, {
                      key: 0,
                      to: item.route,
                      custom: ""
                    }, {
                      default: _withCtx(({ isActive, href, navigate }) => [
                        _createElementVNode("a", _mergeProps({ href: href }, props.action, {
                          onClick: navigate,
                          class: isActive ? 'router-link-active' : ''
                        }), [
                          (item.icon)
                            ? (_openBlock(), _createElementBlock("span", {
                                key: 0,
                                class: _normalizeClass('p-menuitem-icon ' + item.icon)
                              }, null, 2))
                            : _createCommentVNode("", true),
                          _createElementVNode("span", _hoisted_7, _toDisplayString(item.label), 1)
                        ], 16, _hoisted_6)
                      ]),
                      _: 2
                    }, 1032, ["to"]))
                  : (_openBlock(), _createElementBlock("a", _mergeProps({
                      key: 1,
                      href: item.url,
                      target: item.target
                    }, props.action), [
                      (item.icon)
                        ? (_openBlock(), _createElementBlock("span", {
                            key: 0,
                            class: _normalizeClass('p-menuitem-icon ' + item.icon)
                          }, null, 2))
                        : _createCommentVNode("", true),
                      _createElementVNode("span", _hoisted_9, _toDisplayString(item.label), 1)
                    ], 16, _hoisted_8))
              ]),
              _: 1
            }, 8, ["model"])
          ]),
          key: "0"
        }
      : undefined
  ]), 1032, ["model"]))
}
}

})