import {createRouter, createWebHashHistory, createWebHistory} from 'vue-router'
import Home from '../views/Home.vue';
import {store} from '@/store';
import {UserRoleEnum} from '@/model/UserRole';
import {authService} from '@/services/AuthService';
import {AuthenticatedUser} from '@/model/AuthenticatedUser';

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home,
        meta: {
            loginRequired: true
        }
    },
    {
        path: '/bestellungen',
        name: 'bestellungen',
        // route level code-splitting
        // this generates a separate chunk (bestellungen.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "bestellungen" */ '../views/bestellungen/BestellungList.vue'),
        meta: {
            loginRequired: true
        }
    },
    {
        path: '/bestellungen/neu',
        name: 'bestellungAdd',
        component: () => import(/* webpackChunkName: "bestellungen" */ '../views/bestellungen/BestellungEdit.vue'),
        props: (route: any) => ({ ...route.query }),
        meta: {
            loginRequired: true,
            roleRequired: [UserRoleEnum.ROLE_KASSIERER]
        }
    },
    {
        path: '/bestellungen/detail/:id?',
        name: 'bestellungDetail',
        component: () => import(/* webpackChunkName: "bestellungen" */ '../views/bestellungen/BestellungDetail.vue'),
        meta: {
            loginRequired: true
        }
    },
    {
        path: '/bestellungen/:id',
        name: 'bestellungEdit',
        component: () => import(/* webpackChunkName: "bestellungen" */ '../views/bestellungen/BestellungEdit.vue'),
        props: (route: any) => ({ ...route.query }),
        meta: {
            loginRequired: true,
            roleRequired: [UserRoleEnum.ROLE_KASSIERER]
        }
    },
    {
        path: '/bestellungenmitprodukten',
        name: 'bestellungenMitProdukten',
        component: () => import(/* webpackChunkName: "bestellungen" */ '../views/bestellungen/BestellungProduktList.vue'),
        meta: {
            loginRequired: true
        }
    },
    {
        path: '/sammelbestellungen',
        name: 'sammelbestellungList',
        component: () => import(/* webpackChunkName: "sammelbestellungen" */ '../views/sammelbestellungen/SammelbestellungList.vue'),
        meta: {
            loginRequired: true,
            roleRequired: [UserRoleEnum.ROLE_ADMIN]
        }
    },
    {
        path: '/sammelbestellungen/neu',
        name: 'sammelbestellungAdd',
        component: () => import(/* webpackChunkName: "sammelbestellungen" */ '../views/sammelbestellungen/SammelbestellungEdit.vue'),
        meta: {
            loginRequired: true,
            roleRequired: [UserRoleEnum.ROLE_ADMIN]
        }
    },
    {
        path: '/sammelbestellungen/detail/:id?',
        name: 'sammelbestellungDetail',
        component: () => import(/* webpackChunkName: "bestellungen" */ '../views/sammelbestellungen/SammelbestellungDetail.vue'),
        meta: {
            loginRequired: true,
            roleRequired: [UserRoleEnum.ROLE_ADMIN]
        }
    },
    {
        path: '/sammelbestellungen/:id',
        name: 'sammelbestellungEdit',
        component: () => import(/* webpackChunkName: "sammelbestellungen" */ '../views/sammelbestellungen/SammelbestellungEdit.vue'),
        meta: {
            loginRequired: true,
            roleRequired: [UserRoleEnum.ROLE_ADMIN]
        }
    },
    {
        path: '/kasse',
        name: 'kasse',
        component: () => import(/* webpackChunkName: "kasse" */ '../views/kasse/Kasse.vue'),
        meta: {
            loginRequired: true,
            roleRequired: [UserRoleEnum.ROLE_KASSIERER]
        }
    },
    {
        path: '/zahlungen',
        name: 'zahlungen',
        component: () => import(/* webpackChunkName: "zahlungen" */ '../views/zahlungen/ZahlungList.vue'),
        meta: {
            loginRequired: true,
            roleRequired: [UserRoleEnum.ROLE_KASSIERER]
        }
    },
    {
        path: '/zahlungsuebersicht',
        name: 'zahlungUebersicht',
        component: () => import(/* webpackChunkName: "zahlungen" */ '../views/zahlungen/ZahlungUebersicht.vue'),
        meta: {
            loginRequired: true,
            roleRequired: [UserRoleEnum.ROLE_ADMIN]
        }
    },
    {
        path: '/kunden',
        name: 'kunden',
        component: () => import(/* webpackChunkName: "kunden" */ '../views/kunden/KundeList.vue'),
        meta: {
            loginRequired: true,
            roleRequired: [UserRoleEnum.ROLE_KASSIERER]
        }
    },
    {
        path: '/kunden/dubletten',
        name: 'kundeDubletten',
        component: () => import(/* webpackChunkName: "kunden" */ '../views/kunden/KundeDubletten.vue'),
        meta: {
            loginRequired: true,
            roleRequired: [UserRoleEnum.ROLE_ADMIN]
        }
    },
    {
        path: '/kunden/dubletten/merge/:ids',
        name: 'kundeDublettenMerge',
        component: () => import(/* webpackChunkName: "kunden" */ '../views/kunden/KundeDublettenMerge.vue'),
        meta: {
            loginRequired: true,
            roleRequired: [UserRoleEnum.ROLE_ADMIN]
        }
    },
    {
        path: '/kunden/neu',
        name: 'kundeAdd',
        component: () => import(/* webpackChunkName: "kunden" */ '../views/kunden/KundeEdit.vue'),
        props: (route: any) => ({ ...route.query }),
        meta: {
            loginRequired: true,
            roleRequired: [UserRoleEnum.ROLE_KASSIERER]
        }
    },
    {
        path: '/kunden/:id',
        name: 'kundeEdit',
        component: () => import(/* webpackChunkName: "kunden" */ '../views/kunden/KundeEdit.vue'),
        meta: {
            loginRequired: true,
            roleRequired: [UserRoleEnum.ROLE_KASSIERER]
        }
    },
    {
        path: '/kunden/detail/:id/:dublettenIds?',
        name: 'kundeDetail',
        component: () => import(/* webpackChunkName: "kunden" */ '../views/kunden/KundeDetail.vue'),
        meta: {
            loginRequired: true,
            roleRequired: [UserRoleEnum.ROLE_KASSIERER]
        }
    },
    {
        path: '/bestelluebersicht',
        name: 'bestelluebersicht',
        component: () => import(/* webpackChunkName: "bestelluebersicht" */ '../views/bestelluebersicht/Bestelluebersicht.vue'),
        meta: {
            loginRequired: true,
            roleRequired: [UserRoleEnum.ROLE_ADMIN]
        }
    },
    {
        path: '/kalender',
        name: 'kalender',
        component: () => import(/* webpackChunkName: "kalender" */ '../views/kalender/Kalender.vue'),
        meta: {
            loginRequired: true,
            roleRequired: [UserRoleEnum.ROLE_ADMIN]
        }
    },
    {
        path: '/statistik',
        name: 'statistik',
        component: () => import(/* webpackChunkName: "statistik" */ '../views/statistik/Statistik.vue'),
        meta: {
            loginRequired: true,
            roleRequired: [UserRoleEnum.ROLE_ADMIN]
        }
    },
    {
        path: '/newsletter/empfaenger',
        name: 'newsletterEmpfaenger',
        component: () => import(/* webpackChunkName: "newsletter" */ '../views/newsletter/NewsletterEmpfaengerList.vue'),
        meta: {
            loginRequired: true,
            roleRequired: [UserRoleEnum.ROLE_ADMIN]
        }
    },
    {
        path: '/newsletter/empfaenger/neu',
        name: 'newsletterEmpfaengerAdd',
        component: () => import(/* webpackChunkName: "newsletter" */ '../views/newsletter/NewsletterEmpfaengerEdit.vue'),
        meta: {
            loginRequired: true,
            roleRequired: [UserRoleEnum.ROLE_ADMIN]
        }
    },
    {
        path: '/newsletter/versand',
        name: 'newsletter',
        component: () => import(/* webpackChunkName: "newsletter" */ '../views/newsletter/NewsletterList.vue'),
        meta: {
            loginRequired: true,
            roleRequired: [UserRoleEnum.ROLE_ADMIN]
        }
    },
    {
        path: '/newsletter/versand/neu',
        name: 'newsletterAdd',
        component: () => import(/* webpackChunkName: "newsletter" */ '../views/newsletter/NewsletterEdit.vue'),
        meta: {
            loginRequired: true,
            roleRequired: [UserRoleEnum.ROLE_ADMIN]
        }
    },
    {
        path: '/newsletter/versand/:id',
        name: 'newsletterEdit',
        component: () => import(/* webpackChunkName: "newsletter" */ '../views/newsletter/NewsletterEdit.vue'),
        meta: {
            loginRequired: true,
            roleRequired: [UserRoleEnum.ROLE_ADMIN]
        }
    },
    {
        path: '/administration/produkte',
        name: 'produktkList',
        component: () => import(/* webpackChunkName: "produkte" */ '../views/produkte/ProduktList.vue'),
        meta: {
            loginRequired: true,
            roleRequired: [UserRoleEnum.ROLE_ADMIN]
        }
    },
    {
        path: '/administration/produkte/detail/:id',
        name: 'produktDetail',
        component: () => import(/* webpackChunkName: "produkte" */ '../views/produkte/ProduktDetail.vue'),
        meta: {
            loginRequired: true,
            roleRequired: [UserRoleEnum.ROLE_ADMIN]
        }
    },
    {
        path: '/administration/produktkategorien',
        name: 'produktKategorieList',
        component: () => import(/* webpackChunkName: "produktkategorien" */ '../views/produktkategorien/ProduktKategorieList.vue'),
        meta: {
            loginRequired: true,
            roleRequired: [UserRoleEnum.ROLE_ADMIN]
        }
    },
    {
        path: '/administration/lagerbestand',
        name: 'lagerbestand',
        component: () => import(/* webpackChunkName: "lagerbestand" */ '../views/lager/Lagerbestand.vue'),
        meta: {
            loginRequired: true,
            roleRequired: [UserRoleEnum.ROLE_ADMIN]
        }
    },
    {
        path: '/administration/freierverkauf',
        name: 'freierverkauf',
        component: () => import(/* webpackChunkName: "freierverkauf" */ '../views/freierverkauf/FreierVerkauf.vue'),
        meta: {
            loginRequired: true,
            roleRequired: [UserRoleEnum.ROLE_ADMIN]
        }
    },
    {
        path: '/administration/abholtage',
        name: 'abholtagList',
        component: () => import(/* webpackChunkName: "abholtage" */ '../views/abholtage/AbholtagList.vue'),
        meta: {
            loginRequired: true,
            roleRequired: [UserRoleEnum.ROLE_ADMIN]
        }
    },
    {
        path: '/administration/abholtage/neu',
        name: 'abholtagAdd',
        component: () => import(/* webpackChunkName: "abholtage" */ '../views/abholtage/AbholtagEdit.vue'),
        meta: {
            loginRequired: true,
            roleRequired: [UserRoleEnum.ROLE_ADMIN]
        }
    },
    {
        path: '/administration/abholtage/:id',
        name: 'abholtagEdit',
        component: () => import(/* webpackChunkName: "abholtage" */ '../views/abholtage/AbholtagEdit.vue'),
        meta: {
            loginRequired: true,
            roleRequired: [UserRoleEnum.ROLE_ADMIN]
        }
    },
    {
        path: '/administration/abholtage/detail/:id',
        name: 'abholtagDetail',
        component: () => import(/* webpackChunkName: "abholtage" */ '../views/abholtage/AbholtagDetail.vue'),
        meta: {
            loginRequired: true,
            roleRequired: [UserRoleEnum.ROLE_ADMIN]
        }
    },
    {
        path: '/administration/zahlungsarten',
        name: 'zahlungsartList',
        component: () => import(/* webpackChunkName: "zahlungsarten" */ '../views/zahlungsarten/ZahlungsartList.vue'),
        meta: {
            loginRequired: true,
            roleRequired: [UserRoleEnum.ROLE_ADMIN]
        }
    },
    {
        path: '/administration/zahlungsarten/:id',
        name: 'zahlungsartEdit',
        component: () => import(/* webpackChunkName: "zahlungsarten" */ '../views/zahlungsarten/ZahlungsartEdit.vue'),
        meta: {
            loginRequired: true,
            roleRequired: [UserRoleEnum.ROLE_ADMIN]
        }
    },
    {
        path: '/administration/devices',
        name: 'deviceList',
        component: () => import(/* webpackChunkName: "devices" */ '../views/devices/DeviceList.vue'),
        meta: {
            loginRequired: true,
            roleRequired: [UserRoleEnum.ROLE_ADMIN]
        }
    },
    {
        path: '/administration/devices/neu',
        name: 'deviceAdd',
        component: () => import(/* webpackChunkName: "devices" */ '../views/devices/DeviceEdit.vue'),
        meta: {
            loginRequired: true,
            roleRequired: [UserRoleEnum.ROLE_ADMIN]
        }
    },
    {
        path: '/administration/devices/:id',
        name: 'deviceEdit',
        component: () => import(/* webpackChunkName: "devices" */ '../views/devices/DeviceEdit.vue'),
        meta: {
            loginRequired: true,
            roleRequired: [UserRoleEnum.ROLE_ADMIN]
        }
    },
    {
        path: '/administration/user',
        name: 'userList',
        component: () => import(/* webpackChunkName: "user" */ '../views/user/UserList.vue'),
        meta: {
            loginRequired: true,
            roleRequired: [UserRoleEnum.ROLE_ADMIN]
        }
    },
    {
        path: '/administration/user/neu',
        name: 'userAdd',
        component: () => import(/* webpackChunkName: "user" */ '../views/user/UserEdit.vue'),
        meta: {
            loginRequired: true,
            roleRequired: [UserRoleEnum.ROLE_ADMIN]
        }
    },
    {
        path: '/administration/user/:id',
        name: 'userEdit',
        component: () => import(/* webpackChunkName: "user" */ '../views/user/UserEdit.vue'),
        meta: {
            loginRequired: true,
            roleRequired: [UserRoleEnum.ROLE_ADMIN]
        }
    },
    {
        path: '/administration/konfiguration',
        name: 'konfiguration',
        component: () => import(/* webpackChunkName: "konfiguration" */ '../views/konfiguration/Konfiguration.vue'),
        meta: {
            loginRequired: true,
            roleRequired: [UserRoleEnum.ROLE_ADMIN]
        }
    },
    {
        path: '/hilfe',
        name: 'hilfe',
        component: () => import(/* webpackChunkName: "hilfe" */ '../views/hilfe/Hilfe.vue'),
        meta: {
            loginRequired: true
        }
    },
    {
        path: '/einstellungen',
        name: 'einstellungen',
        component: () => import(/* webpackChunkName: "einstellungen" */ '../views/einstellungen/Einstellungen.vue'),
        meta: {
            loginRequired: true
        }
    },
    {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
        meta: {
            loginRequired: false
        }
    },
];

export const router = createRouter({
    history: createWebHashHistory(),
    routes,
})

router.beforeEach((to, from, next) => {

    const user: AuthenticatedUser = store.state.user;

    if (to.meta!.loginRequired && !authService.isUserLoggedIn(user)) {
        // moeglicherweise ist die Session abgelaufen - deshalb signOut aufrufen, damit Status aktualisiert wird
        store.dispatch('signOut').then();
        next('/login');

    } else if (to.meta!.roleRequired) {
        let userIsInRole = false;

        // @ts-ignore
        to.meta!.roleRequired.forEach((role: UserRoleEnum) => {
            userIsInRole = userIsInRole || authService.isUserInRole(user, role);
        });

        if (userIsInRole) {
            next();
        } else {
            console.log('Benutzer hat nicht die erforderliche Rolle');
        }

    } else {
        next();
    }
});
