import {ErrorResponse} from '@/model/ErrorResponse';

export default class ResponseUtil {
    public static getErrorMessage(error: ErrorResponse, defaultMessage: string): string {
        if (error && error.applicationException) {
            return error.message;
        } else {
            return defaultMessage;
        }
    }
}
