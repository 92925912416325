export default class RequestUtil {
    public static convertObjectToRequestParams(object: any): string {
        let params = '';
        for (const key in object) {
            if (key) {
                const value = encodeURIComponent(this.getObjectByKey(object, key));

                if (value == null || value === 'undefined') {
                    continue;
                }

                if (params !== '') {
                    params += '&';
                }
                params += key + '=' + value;
            }
        }
        return params;
    }

    private static getObjectByKey(object: any, key: any): string {
        const obj = object[key];

        // Datumswerte werden - sofern sie nicht bereits "stringified" wurden, in einem falschen Datumsformat übertragen.
        // Deshalb werden sie hier in einen ISO-String konvertiert (unter Berücksichtigung der Zeitzone).
        if (obj instanceof Date) {
            const timeMEZ = obj.getTime() - (obj.getTimezoneOffset() * 60000);
            return new Date(timeMEZ).toISOString();
        }
        return obj;
    }
}
