import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, renderSlot as _renderSlot, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, TransitionGroup as _TransitionGroup, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "page-header surface-section mb-4"
}
const _hoisted_2 = { class: "flex align-items-start flex-column lg:justify-content-between lg:flex-row" }
const _hoisted_3 = { class: "pt-1" }
const _hoisted_4 = {
  class: "font-medium text-2xl text-800",
  "data-test": "page-title"
}
const _hoisted_5 = { class: "ml-2" }
const _hoisted_6 = {
  id: "page-header-buttons",
  class: "page-header-buttons mt-3 lg:mt-0 flex align-items-start flex-column md:flex-row flex-wrap w-full md:w-auto"
}

import {onMounted, onUpdated, PropType} from 'vue';
import {NotificationMessage} from '@/model/NotificationMessage';


export default /*@__PURE__*/_defineComponent({
  __name: 'PageHeader',
  props: {
    title: String,
    loading: Boolean,
    messages: {
        type: Array as PropType<Array<NotificationMessage>>,
        required: true
    },
    showHeader: {
        type: Boolean,
        default: true
    }
},
  setup(__props) {

const props = __props

onMounted(() => {
    addClassesToButtons();
});

onUpdated(() => {
    addClassesToButtons();
});

function addClassesToButtons() {
    const pageHeaderButtons = document.getElementById('page-header-buttons');
    if (pageHeaderButtons) {
        const buttons = pageHeaderButtons.getElementsByTagName('button');
        for (let button of buttons) {
            if (!button.classList.contains('w-full')) {
                button.classList.add('w-full', 'md:w-auto');
            }
        }
    }
}

return (_ctx: any,_cache: any) => {
  const _component_Message = _resolveComponent("Message")!
  const _component_Toast = _resolveComponent("Toast")!
  const _component_ConfirmDialog = _resolveComponent("ConfirmDialog")!
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (__props.showHeader)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createTextVNode(_toDisplayString(__props.title), 1),
                _createElementVNode("span", _hoisted_5, [
                  _renderSlot(_ctx.$slots, "title-addition")
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _renderSlot(_ctx.$slots, "buttons")
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_TransitionGroup, {
      name: "p-message",
      tag: "div",
      "data-test": "notification"
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.messages, (msg) => {
          return (_openBlock(), _createBlock(_component_Message, {
            severity: msg.severity,
            key: msg.id
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(msg.content), 1)
            ]),
            _: 2
          }, 1032, ["severity"]))
        }), 128))
      ]),
      _: 1
    }),
    _createVNode(_component_Toast, { position: "top-center" }),
    _createVNode(_component_ConfirmDialog, {
      "data-test": "confirm-dialog",
      style: { width: '50vw' },
      breakpoints: { '960px': '75vw', '641px': '100vw' }
    }),
    _createVNode(_component_Dialog, {
      visible: __props.loading,
      modal: true,
      closable: false,
      "show-header": false,
      class: "loading-dialog"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ProgressSpinner)
      ]),
      _: 1
    }, 8, ["visible"])
  ], 64))
}
}

})