import AbstractCrudService from '@/services/AbstractCrudService';
import {Aufgabe} from '@/model/Aufgabe';

class AufgabeService extends AbstractCrudService {

    private readonly basePath: string = '/aufgaben';

    public getOffeneAufgaben() {
        return this.getList(this.basePath + '/search/findAllNichtErledigt');
    }

    public saveAufgabe(aufgabe: Aufgabe) {
        return this.save(this.basePath, aufgabe);
    }
}

// Export a singleton instance in the global namespace
export const aufgabeService = new AufgabeService();
