import {Pageable} from '@/model/Pageable';
import AbstractRestClientService from '@/services/AbstractRestClientService';

export default abstract class AbstractCrudService extends AbstractRestClientService {

    protected save(path: string, obj: any) {
        if (obj.id == null || obj.id === 0) {
            return this.sendPost(path, obj);
        } else {
            return this.sendPut(path + '/' + obj.id, obj);
        }
    }

    protected getList(path: string, projection?: string) {
        path = this.addProjectionToPath(path, projection);
        return this.sendGet(path);
    }

    protected getSortableList(path: string, sortField: string, sortOrder: string, projection?: string) {
        path = this.addSortingToPath(path, sortField, sortOrder);
        path = this.addProjectionToPath(path, projection);
        return this.sendGet(path);
    }

    protected getPageableList(path: string, paging: Pageable, sortField: string, sortOrder: string, projection?: string) {
        path = this.addPagingToPath(path, paging);
        path = this.addSortingToPath(path, sortField, sortOrder);
        path = this.addProjectionToPath(path, projection);
        return this.sendGet(path);
    }

    protected get(path: string, projection?: string) {
        path = this.addProjectionToPath(path, projection);
        return this.sendGet(path);
    }

    protected delete(path: string) {
        return this.sendDelete(path);
    }


    private addPagingToPath(path: string, paging: Pageable): string {
        if (paging) {
            path = this.addParamDelimterToPath(path);
            path += 'page=' + (paging.number) + '&size=' + (paging.size);
        }
        return path;
    }

    private addSortingToPath(path: string, sortField: string, sortOrder: string): string {
        if (sortField && sortOrder) {
            path = this.addParamDelimterToPath(path);
            path += 'sort=' + sortField + ',' + sortOrder;
        }
        return path;
    }

    private addProjectionToPath(path: string, projection?: string): string {
        if (projection) {
            path = this.addParamDelimterToPath(path);
            path += 'projection=' + projection;
        }
        return path;
    }

    private addParamDelimterToPath(path: string): string {
        if (path.indexOf('?') > 0) {
            path += '&';
        } else {
            path += '?';
        }
        return path;
    }
}
