import {createApp} from 'vue'
import App from './App.vue';
import {router} from './router';
import {key, store} from './store';
import {vueDebounce} from 'vue-debounce';

import PrimeVue from 'primevue/config';
import Button from 'primevue/button';
import Card from 'primevue/card';
import Textarea from 'primevue/textarea';
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import ToggleSwitch from 'primevue/toggleswitch';
import AutoComplete from 'primevue/autocomplete';
import Checkbox from 'primevue/checkbox';
import Select from 'primevue/select';
import MultiSelect from 'primevue/multiselect';
import SelectButton from 'primevue/selectbutton';
import DatePicker from 'primevue/datepicker';
import Menubar from 'primevue/menubar';
import Menu from 'primevue/menu';
import TieredMenu from 'primevue/tieredmenu';
import Message from 'primevue/message';
import Tag from 'primevue/tag';
import Accordion from 'primevue/accordion';
import AccordionPanel from 'primevue/accordionpanel';
import AccordionHeader from 'primevue/accordionheader';
import AccordionContent from 'primevue/accordioncontent';
import Editor from 'primevue/editor';
import ProgressSpinner from 'primevue/progressspinner';
import Toast from 'primevue/toast';
import Dialog from 'primevue/dialog';
import ConfirmDialog from 'primevue/confirmdialog';
import Popover from 'primevue/popover';
import DataTable from 'primevue/datatable';
import DataView from 'primevue/dataview';
import Column from 'primevue/column';
import Tabs from 'primevue/tabs';
import Tab from 'primevue/tab';
import TabList from 'primevue/tablist';
import TabPanels from 'primevue/tabpanels';
import TabPanel from 'primevue/tabpanel';
import Chart from 'primevue/chart';
import {useConfirm} from 'primevue/useconfirm';
import {useToast} from 'primevue/usetoast';
import ConfirmationService from 'primevue/confirmationservice';
import ToastService from 'primevue/toastservice';
import Tooltip from 'primevue/tooltip';

import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';

import { definePreset } from '@primevue/themes';
import Lara from '@primevue/themes/lara';

const IgsPreset = definePreset(Lara, {
    semantic: {
        primary: {
            50: '{blue.50}',
            100: '{blue.100}',
            200: '{blue.200}',
            300: '{blue.300}',
            400: '{blue.400}',
            500: '{blue.500}',
            600: '{blue.600}',
            700: '{blue.700}',
            800: '{blue.800}',
            900: '{blue.900}',
            950: '{blue.950}'
        },
        secondary: {
            50: '{purple.50}',
            100: '{purple.100}',
            200: '{purple.200}',
            300: '{purple.300}',
            400: '{purple.400}',
            500: '{purple.500}',
            600: '{purple.600}',
            700: '{purple.700}',
            800: '{purple.800}',
            900: '{purple.900}',
            950: '{purple.950}'
        },
        formField: {
            paddingX: '0.5rem',
            paddingY: '0.5rem',
            borderRadius: '{border.radius.sm}',
            focusRing: {
                width: '0',
                style: 'none',
                color: 'transparent',
                offset: '0',
                shadow: 'none'
            },
            transitionDuration: '{transition.duration}'
        },
    },
    components: {
        datatable: {
            column: {
                title: {
                    fontWeight: '500'
                }
            }
        },
        button: {
            label: {
                fontWeight: '400'
            }
        },
        tabs: {
            tabpanel: {
                padding: '0.875rem 1.125rem 1.125rem 0'
            }
        }
    }
});

const app = createApp(App);

app.use(PrimeVue, {
    theme: {
        preset: IgsPreset
    },
    locale: {
        startsWith: 'beginnt mit',
        contains: 'enthält',
        notContains: 'enthält nicht',
        endsWith: 'endet mit',
        equals: 'ist gleich',
        notEquals: 'ist nicht gleich',
        noFilter: 'kein Filter',
        lt: 'kleiner',
        lte: 'kleiner oder gleich',
        gt: 'größer',
        gte: 'größer oder gleich',
        dateIs: 'Datum ist',
        dateIsNot: 'Datum ist nicht',
        dateBefore: 'Datum ist vor',
        dateAfter: 'Datum ist nach',
        clear: 'Löschen',
        apply: 'Anwenden',
        matchAll: 'Match All',
        matchAny: 'Match Any',
        addRule: 'Regel hinzufügen',
        removeRule: 'Regel entfernen',
        accept: 'Ja',
        reject: 'Nein',
        choose: 'Auswählen',
        upload: 'Upload',
        cancel: 'Abbrechen',
        completed: 'Completed',
        pending: 'Pending',
        dayNames: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
        dayNamesShort: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
        dayNamesMin: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
        monthNames: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
        monthNamesShort: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
        today: 'Heute',
        chooseYear: 'Jahr auswählen',
        chooseMonth: 'Monat auswählen',
        chooseDate: 'Datum auswählen',
        prevDecade: 'Previous Decade',
        nextDecade: 'Next Decade',
        prevYear: 'vorheriges Jahr',
        nextYear: 'nächstes Jahr',
        prevMonth: 'vorheriger Monat',
        nextMonth: 'nächster Monat',
        prevHour: 'vorherige Stunde',
        nextHour: 'nächste Stunde',
        prevMinute: 'vorherige Minute',
        nextMinute: 'nächste Minute',
        prevSecond: 'vorherige Sekunde',
        nextSecond: 'nächste Sekunde',
        am: 'vormittags',
        pm: 'nachmittags',
        weekHeader: 'KW',
        firstDayOfWeek: 1,
        dateFormat: 'dd.mm.yy',
        weak: 'Woche',
        medium: 'Medium',
        strong: 'Strong',
        passwordPrompt: 'Bitte Kennwort eingeben',
        emptyFilterMessage: 'keine Treffer gefunden',
        searchMessage: '{0} Treffer',
        selectionMessage: '{0} ausgewählt',
        emptySelectionMessage: 'nichts ausgewählt',
        emptySearchMessage: 'keine Treffer',
        emptyMessage: 'keine Auswahl verfügbar',
        aria: {
            trueLabel: 'wahr',
            falseLabel: 'falsch',
            nullLabel: 'nicht ausgewählt',
            star: '1 star',
            stars: '{star} stars',
            selectAll: 'alles ausgewählt',
            unselectAll: 'nichts ausgewählt',
            close: 'schließen',
            previous: 'zurück',
            next: 'vor',
            navigation: 'Navigation',
            pageLabel: '{page}'
        }
    }
});
app.use(store, key);
app.use(router);
app.use(ConfirmationService);
app.use(ToastService);

app.component('Button', Button);
app.component('Card', Card);
app.component('Textarea', Textarea);
app.component('IconField', IconField);
app.component('InputIcon', InputIcon);
app.component('InputText', InputText);
app.component('InputNumber', InputNumber);
app.component('ToggleSwitch', ToggleSwitch);
app.component('AutoComplete', AutoComplete);
app.component('Checkbox', Checkbox);
app.component('Select', Select);
app.component('MultiSelect', MultiSelect);
app.component('SelectButton', SelectButton);
app.component('DatePicker', DatePicker);
app.component('Menubar', Menubar);
app.component('Menu', Menu);
app.component('TieredMenu', TieredMenu);
app.component('Message', Message);
app.component('Tag', Tag);
app.component('Accordion', Accordion);
app.component('AccordionPanel', AccordionPanel);
app.component('AccordionHeader', AccordionHeader);
app.component('AccordionContent', AccordionContent);
app.component('Editor', Editor);
app.component('ProgressSpinner', ProgressSpinner);
app.component('Toast', Toast);
app.component('Dialog', Dialog);
app.component('ConfirmDialog', ConfirmDialog);
app.component('Popover', Popover);
app.component('DataTable', DataTable);
app.component('DataView', DataView);
app.component('Column', Column);
app.component('Tabs', Tabs);
app.component('Tab', Tab);
app.component('TabList', TabList);
app.component('TabPanels', TabPanels);
app.component('TabPanel', TabPanel);
app.component('Chart', Chart);
app.component('useConfirm', useConfirm);
app.component('useToast', useToast);

app.directive('tooltip', Tooltip);
app.directive('debounce', vueDebounce({lock: true}))

app.mount('#app');

// Die Standard Date.toJSON-Funktion liefert eine UTC-Zeit ohne Berücksichtigung der Zeitzone zurück.
// Hier wird die Funktion so überschrieben, dass die der Offset der Zeitzone eingerechnet wird.
Date.prototype.toJSON = function () {
    const timeMEZ = this.getTime() - (this.getTimezoneOffset() * 60000);
    return new Date(timeMEZ).toISOString();
};
