import axios from 'axios';
import {UserRoleEnum} from '@/model/UserRole';
import VueJwtDecode from 'vue-jwt-decode';
import {AuthenticatedUser} from '@/model/AuthenticatedUser';

const API_URL = '/api/auth/';

class AuthService {
    public login(username: string, password: string) {
        return axios
            .post(API_URL + 'signin', {
                username,
                password
            })
            .then((response) => {
                if (response.data.accessToken) {
                    localStorage.setItem('user', JSON.stringify(response.data));
                }
                return response.data;
            })
            .catch((error) => {
                console.log('authentication: error=' + JSON.stringify(error));
                return Promise.reject(error);
            });
    }

    public logout() {
        localStorage.removeItem('user');
    }

    public isUserLoggedIn(user: AuthenticatedUser) {
        if (user) {
            const decodedToken = VueJwtDecode.decode(user.accessToken);
            return decodedToken && (decodedToken.exp * 1000) > new Date().getTime();
        }
        return false;
    }

    public isUserInRole(user: AuthenticatedUser, role: UserRoleEnum) {
        if (!user || !user.roles) {
            return false;
        }

        switch (role) {
            case UserRoleEnum.ROLE_ADMIN:
                return user.roles.includes(UserRoleEnum.ROLE_ADMIN.toString());
            case UserRoleEnum.ROLE_KASSIERER:
                return user.roles.includes(UserRoleEnum.ROLE_KASSIERER.toString()) ||
                    user.roles.includes(UserRoleEnum.ROLE_ADMIN.toString());
            case UserRoleEnum.ROLE_HELFER:
                return user.roles.includes(UserRoleEnum.ROLE_HELFER.toString()) ||
                    user.roles.includes(UserRoleEnum.ROLE_KASSIERER.toString()) ||
                    user.roles.includes(UserRoleEnum.ROLE_ADMIN.toString());
            default:
                return false;
        }
    }
}

// Export a singleton instance in the global namespace
export const authService = new AuthService();
