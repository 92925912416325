import {InjectionKey} from 'vue'
import {createStore, Store, useStore as baseUseStore} from 'vuex'
import {AuthenticatedUser} from '@/model/AuthenticatedUser';
import {UserStatus} from '@/model/UserStatus';
import {authService} from '@/services/AuthService';
import {UserRoleEnum} from '@/model/UserRole';
import {Warenkorb, WarenkorbPosition, WarenkorbZahlung} from '@/model/Warenkorb';

const storedUser = localStorage.getItem('user');

export interface State {
    user: AuthenticatedUser,
    status: UserStatus
    warenkorb: Warenkorb
}

export const key: InjectionKey<Store<State>> = Symbol()

export const store = createStore<State>({
    state: {
        user: storedUser ? JSON.parse(storedUser) : null,
        status: initStatus(),
        warenkorb: new Warenkorb()
    },

    mutations: {
        loginSuccess(state, user: AuthenticatedUser): void {
            state.user = user;
            state.status = createStatus(state.user);
        },

        loginFailure(state): void {
            // @ts-ignore
            state.user = undefined;
            state.status = createStatus(state.user);
        },

        logout(state): void {
            // @ts-ignore
            state.user = undefined;
            state.status = createStatus(state.user);
        },

        addPositionenToWarenkorb(state, positionen: WarenkorbPosition[]): void {
            state.warenkorb.positionen.push(...positionen);
        },

        setKundeToWarenkorb(state, kunde: string): void {
            state.warenkorb.kunde = kunde;
        },

        clearWarenkorb(state): void {
            state.warenkorb.positionen = [];
            state.warenkorb.zahlung = new WarenkorbZahlung();
            state.warenkorb.kunde = '';
        }
    },

    actions: {
        login(context, data: any): Promise<any> {
            return authService.login(data.username, data.password).then(
                (user) => {
                    context.commit('loginSuccess', user);
                    return Promise.resolve(user);
                },
                (error) => {
                    context.commit('loginFailure: ' + error);
                    const message =
                        (error.response && error.response.data && error.response.data.message) ||
                        error.message ||
                        error.toString();
                    return Promise.reject(message);
                }
            );
        },

        signOut(context): void {
            authService.logout();
            context.commit('logout');
        },

        tokenExpired(context): void {
            authService.logout();
            context.commit('logout');
        },

        addPositionenToWarenkorb(context, positionen: WarenkorbPosition[]) {
            context.commit('addPositionenToWarenkorb', positionen);
        },

        setKundeToWarenkorb(context, kunde: string) {
            context.commit('setKundeToWarenkorb', kunde);
        },

        clearWarenkorb(context) {
            context.commit('clearWarenkorb');
        }
    }
})

export function useStore() {
    return baseUseStore(key)
}

function initStatus(): UserStatus {
    if (storedUser) {
        return createStatus(JSON.parse(storedUser));
    } else {
        return createStatus(undefined);
    }
}

function createStatus(user?: AuthenticatedUser): UserStatus {
    const userStatus = new UserStatus();
    if (user) {
        userStatus.isLoggedIn = authService.isUserLoggedIn(user);
        userStatus.isAdmin = authService.isUserInRole(user, UserRoleEnum.ROLE_ADMIN);
        userStatus.isKassierer = authService.isUserInRole(user, UserRoleEnum.ROLE_KASSIERER);
    }
    return userStatus;
}
