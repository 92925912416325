import {Ref, ref} from 'vue';
import {useConfirm} from 'primevue/useconfirm';
import {useToast} from 'primevue/usetoast';
import {ErrorResponse} from '@/model/ErrorResponse';
import {NotificationMessage} from '@/model/NotificationMessage';
import ResponseUtil from '@/utils/ResponseUtil';

export const useNotification = () => {

    const messages: Ref<NotificationMessage[]> = ref([]);
    const confirm = useConfirm();
    const toast = useToast();

    function clear() {
        messages.value = [];
    }

    function handleApiError(error: any, defaultMessage: string) {
        console.log('Error: ' + JSON.stringify(error));
        if (error.response) {
            const errorResponse: ErrorResponse = error.response.data;
            showError(ResponseUtil.getErrorMessage(errorResponse, defaultMessage));
        } else {
            showError(defaultMessage);
        }
    }

    function showError(message: string) {
        showMessage(message, 'error')
    }

    function showWarning(message: string) {
        showMessage(message, 'warn')
    }

    function showMessage(message: string, severity: string) {
        const m = new NotificationMessage();
        m.id = messages.value.length + 1;
        m.severity = severity;
        m.content = message;
        messages.value.push(m);
    }

    function toastInfo(message: string, detailMessage?: string) {
        toast.add({
            severity: 'success',
            summary: message,
            detail: detailMessage,
            life: 3000
        });
    }

    function toastStickyInfo(message: string, detailMessage?: string) {
        toast.add({
            severity: 'success',
            summary: message,
            detail: detailMessage
        });
    }

    function toastError(message: string, detailMessage?: string) {
        toast.add({
            severity: 'error',
            summary: message,
            detail: detailMessage,
            life: 3000
        });
    }

    function toastStickyError(message: string, detailMessage?: string) {
        toast.add({
            severity: 'error',
            summary: message,
            detail: detailMessage
        });
    }

    function confirmDialog(message: string, acceptCallback: () => void) {
        confirm.require({
            message: message,
            header: 'Achtung',
            icon: 'pi pi-exclamation-circle',
            acceptLabel: 'OK',
            rejectLabel: 'Abbrechen',
            accept: () => {
                acceptCallback.call(null);
            }
        });
    }

    function confirmDialogDanger(message: string, acceptLabel: string, acceptCallback: () => void) {
        confirm.require({
            message: message,
            header: 'Achtung',
            icon: 'pi pi-exclamation-circle',
            acceptLabel: acceptLabel,
            acceptClass: 'p-button-danger',
            rejectLabel: 'Abbrechen',
            defaultFocus: 'reject',
            accept: () => {
                acceptCallback.call(null);
            }
        });
    }

    function yesNoDialog(message: string, acceptCallback: () => void, rejectCallback: () => void) {
        confirm.require({
            message: message,
            header: 'Frage',
            icon: 'pi pi-question-circle',
            acceptLabel: 'Ja',
            rejectLabel: 'Nein',
            accept: () => {
                acceptCallback.call(null);
            },
            reject: () => {
                rejectCallback.call(null);
            }
        });
    }

    return {
        messages,
        clear,
        handleApiError,
        showError,
        showWarning,
        toastInfo,
        toastStickyInfo,
        toastError,
        toastStickyError,
        confirmDialog,
        confirmDialogDanger,
        yesNoDialog
    };
};
